@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "~@angular/material/prebuilt-themes/pink-bluegrey.css";
// @import "~@angular/material/prebuilt-themes/purple-green.css";

// @import "~@swimlane/ngx-dnd/release/index.css";

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}


/**
 * @swimlane/ngx-dnd v"3.1.2" (https://github.com/swimlane/ngx-dnd)
 * Copyright 2017
 * Licensed under MIT
 */
 .ngx-dnd-item {
  transition: opacity 0.4s ease-in-out;
  display: block;

  &:not(.has-handle):not(.move-disabled),
  &.has-handle [ngxdraghandle],
  &.has-handle [ngxDragHandle] {
    cursor: move;
    cursor: grab;
  }

  .ngx-dnd-content {
    user-select: none;
  }
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
}

.gu-hide {
  display: none !important; }

.gu-unselectable {
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
}

.mat-button {
  text-transform: uppercase;
}
